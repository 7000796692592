<template>
    <BT-Blade-Items
        :canSelect="false"
        navigation="client-requests"
        useServerPagination
        :headers="[
            { text: 'Company Name', value: 'buyer.companyName' },
            { text: 'Date', value: 'requestedOn', textFilter: 'toLongDateAndTime' },
            { text: 'Message', value: 'requesterMessage' },
            { text: 'Status', value: 'isApproved', display: true }]"
        :loadingMsg="loadingMsg"
        title="Client Requests">
        <template v-slot:itemActions="{ item }">
            <BT-Dialog
                v-if="item.isApproved == null"
                :item="item"
                :getOnOpen="getRequestOptions"
                icon="mdi-check"
                width="500"
                label="Approve"
                @ok="approveRequest">
                <template slot-scope="{ item }">
                    <BT-Select
                        navigation="courier-price-tiers"
                        itemValue="id"
                        itemText="priceTierName"
                        v-model="item.priceTierID"
                        label="Price Tier" />
                    <div>OR</div>
                    <BT-Select
                        navigation="client-templates"
                        itemValue="id"
                        itemText="templateName"
                        v-model="item.templateID"
                        label="Client Template" />
                </template>
            </BT-Dialog>
            <v-btn 
                v-if="item.isApproved == null"
                @click.stop="rejectRequest(item)" 
                title="Reject Request"
                icon>
                <v-icon color="red">mdi-close</v-icon>
            </v-btn>
        </template>
        <template v-slot:isApproved="{ item }">
            <span v-if="item.isApproved == null">Awaiting Response</span>
            <span v-else-if="item.isApproved === true">Approved</span>
            <span v-else-if="item.isApproved === false">Rejected</span>
        </template>
        <template v-slot:bottom>
            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Items> 
</template>

<script>
export default {
    name: 'Client-Requests-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            loadingMsg: null,
            msg: null
        }
    },
    methods: {
        getRequestOptions(req) {
            return {
                request: req,
                priceTierID: null,
                templateID: null
            };
        },
        async approveRequest(options) {
            var req = options.request;
            var patch = {
                id: req.id,
                rowVersion: req.rowVersion,
                isApproved: true,
                priceTierID: options.priceTierID,
                templateID: options.templateID
            };
            
            try {
                this.loadingMsg = 'Approving Request';
                var res = await this.$BlitzIt.store.patch('client-requests', patch);
                req.isApproved = true;
                req.rowVersion = res.rowVersion;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async rejectRequest(req) {
            try {
                this.loadingMsg = 'Rejecting Request';
                var res = await this.$BlitzIt.store.patch('client-requests', { id: req.id, rowVersion: req.rowVersion, isApproved: false });
                req.isApproved = false;
                req.rowVersion = res.rowVersion;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>